<template>
  <div
    class="w-full bg-promy-gray-50 px-20 py-5 sm:p-0 flex flex-col space-y-5 sm:px-5 sm:mb-16"
  >
    <BackToProfileButton classes="sm:mt-5" />
    <div class="flex flex-col bg-white rounded-lg shadow-md py-14 px-12 sm:p-0">
      <span class="text-promy-blue-300 font-bold text-2xl mt-4 mb-10 sm:px-7">
        {{ title }}
      </span>
      <div class="flex flex-col">
        <div class="border border-promy-gray-100 p-7 rounded-xl">
          <div
            class="overflow-y-auto max-h-[50vh] md:max-h-[40vh] scrollbar-thin scrollbar-thumb-promy-gray-180 scrollbar-track-promy-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full pr-8 break-words"
            style="word-break: break-word"
          >
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
}
</script>
